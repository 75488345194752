import { observer } from "mobx-react"
import { FormControl, Tabs, View } from "reshaped"

import { CodeEditor } from "../../../components/PipelineCreation/CodeEditor/component"
import { pipelineEmailEncryptionCreatePageStore } from "../page.model"

export const Transform = observer(() => {
	const store = pipelineEmailEncryptionCreatePageStore

	return (
		<View minHeight={140} gap={4}>
			<FormControl>
				<Tabs>
					<Tabs.List>
						<Tabs.Item value="handler">handler.py</Tabs.Item>
						<Tabs.Item value="requirements">
							requirements.txt
						</Tabs.Item>
					</Tabs.List>
					<Tabs.Panel value="handler">
						<CodeEditor
							softWrap={store.editorSettings.softWrap}
							showInvisibles={store.editorSettings.showInvisibles}
							value={store.transformerForm.handler.value}
							minHeight="300px"
							height="62dvh"
							paddingTop={3}
							readOnly
						/>
					</Tabs.Panel>
					<Tabs.Panel value="requirements">
						<CodeEditor
							softWrap={store.editorSettings.softWrap}
							showInvisibles={store.editorSettings.showInvisibles}
							value={store.transformerForm.requirements.value}
							minHeight="300px"
							height="62dvh"
							paddingTop={3}
							readOnly
						/>
					</Tabs.Panel>
				</Tabs>
			</FormControl>
		</View>
	)
})

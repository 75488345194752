import { easeInOut, m } from "framer-motion"
import { observer } from "mobx-react"
import { CheckCircle } from "react-feather"
import { Button, Hidden, Text, View } from "reshaped"

import { unauthenticatedPageStore } from "./page.model"
import css from "./page.module.css"

import Logo from "./logo.svg?react"

export const Unauthenticated = observer(() => {
	return (
		<View justify="center" align="center" height="100dvh" width="100dvw">
			<View direction="row" width="100dvw" align="center">
				<View.Item columns={{ s: 12, m: 6 }}>
					<View gap={8} align="center" paddingBottom={10}>
						<View gap={3} align="center">
							<Logo width="275px" class={css.logo} />
							<Text variant="title-4" color="neutral">
								Data streaming, for python engineers.
							</Text>
						</View>
						<View gap={3} align="center">
							<View align="stretch" direction="row" gap={10}>
								<View width="150px">
									<Button
										variant="solid"
										color="primary"
										size="large"
										fullWidth
										onClick={() =>
											unauthenticatedPageStore.login()
										}
									>
										Login
									</Button>
								</View>
								<View width="150px">
									<Button
										variant="solid"
										color="neutral"
										size="large"
										fullWidth
										onClick={() =>
											unauthenticatedPageStore.signUp()
										}
									>
										Signup
									</Button>
								</View>
							</View>
							<View align="end" justify="end">
								<Text variant="body-3" color="neutral">
									Signup for free - no Credit Card required.
								</Text>
							</View>
						</View>
					</View>
				</View.Item>
				<Hidden hide={{ s: true, m: false }}>
					<View.Item columns={6} gapBefore={5}>
						<View
							gap={10}
							paddingBottom={10}
							backgroundColor="page-faded"
							height="100dvh"
							justify="center"
							align="center"
							className={css.rightHalf}
						>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.2,
								}}
							>
								<View
									width={93}
									direction="row"
									gap={5}
									padding={4}
									align="center"
								>
									<CheckCircle />
									<Text variant="title-5">
										Get pipelines ready in minutes
									</Text>
								</View>
							</m.div>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.3,
								}}
							>
								<View
									width={93}
									direction="row"
									gap={5}
									padding={4}
									align="center"
								>
									<CheckCircle />
									<Text variant="title-5">
										Serverless Transformation-Engine
									</Text>
								</View>
							</m.div>
							<m.div
								initial={{ opacity: 0, x: 20 }}
								animate={{
									opacity: 1,
									x: 0,
								}}
								transition={{
									ease: easeInOut,
									duration: 0.4,
									delay: 0.4,
								}}
							>
								<View
									width={93}
									direction="row"
									gap={5}
									padding={4}
									align="center"
								>
									<CheckCircle />
									<Text variant="title-5">
										Test & build locally
									</Text>
								</View>
							</m.div>
						</View>
					</View.Item>
				</Hidden>
			</View>
		</View>
	)
})

import { observer } from "mobx-react"
import { Trash2 } from "react-feather"
import { ActionBar, Button, Hidden, Modal, TextField, View } from "reshaped"

import { modalsStore } from "#modals"

export const EditEnvironmentVariablesModal = observer(() => {
	const editEnvironmentVariablesModalStore =
		modalsStore.editEnvironmentVariables

	return (
		<Modal
			className="modal"
			active={editEnvironmentVariablesModalStore.isOpen}
			padding={0}
			size="650px"
			blurredOverlay
		>
			<View padding={4} gap={4}>
				<Modal.Title>Edit Environment Variables</Modal.Title>

				<Modal.Subtitle>
					Manage the Environment Variables available to your Handler
					Function.
				</Modal.Subtitle>

				<form
					onSubmit={(event) => {
						event.stopImmediatePropagation()
						event.preventDefault()
						editEnvironmentVariablesModalStore.closeModalAndReturnVariables()
					}}
				>
					<View gap={4}>
						{editEnvironmentVariablesModalStore.environmentVariables.map(
							({ name, value }, index) => {
								return (
									// biome-ignore lint/correctness/useJsxKeyInIterable: <explanation>
									<View gap={4}>
										<View gap={4} direction="row">
											<View direction="row" grow gap={4}>
												<View.Item columns={6}>
													<TextField
														name={`variableKey${index}`}
														placeholder="Name"
														value={name}
														onChange={({
															value,
														}) => {
															editEnvironmentVariablesModalStore.setVariableKey(
																index,
																value,
															)
														}}
														inputAttributes={{
															"data-1p-ignore": true,
														}}
													/>
												</View.Item>
												<View.Item columns={6}>
													<TextField
														name={`variableValue${index}`}
														placeholder="Value"
														value={value}
														onChange={({
															value,
														}) => {
															editEnvironmentVariablesModalStore.setVariableValue(
																index,
																value,
															)
														}}
														inputAttributes={{
															"data-1p-ignore": true,
														}}
													/>
												</View.Item>
											</View>

											<Button
												icon={Trash2}
												color="critical"
												variant="outline"
												onClick={() => {
													editEnvironmentVariablesModalStore.removeVariable(
														index,
													)
												}}
											/>
										</View>
									</View>
								)
							},
						)}

						<View.Item>
							<Button
								color="positive"
								variant="faded"
								onClick={() => {
									editEnvironmentVariablesModalStore.addVariable()
								}}
							>
								Add Environment Variable
							</Button>
						</View.Item>
					</View>

					<Hidden hide>
						<button type="submit" />
					</Hidden>
				</form>

				{alert}
			</View>
			<ActionBar>
				<View direction="row" gap={4} justify="end">
					<Button
						color="neutral"
						disabled={editEnvironmentVariablesModalStore.isSaving}
						onClick={() => {
							editEnvironmentVariablesModalStore.closeModal()
						}}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						loading={editEnvironmentVariablesModalStore.isSaving}
						onClick={() =>
							editEnvironmentVariablesModalStore.closeModalAndReturnVariables()
						}
					>
						Save Environment Variables
					</Button>
				</View>
			</ActionBar>
		</Modal>
	)
})

import { types } from "mobx-state-tree"

export const TransformModalModel = types
	.model("TransformModal", {
		isOpen: types.optional(types.boolean, false),
		withClose: types.optional(types.boolean, false),
		nextButtonName: types.optional(types.string, ""),
		prevButtonName: types.optional(types.string, ""),
		nextButtonFunctionId: types.optional(types.string, ""),
		prevButtonFunctionId: types.optional(types.string, ""),
	})
	.actions((self) => {
		return {
			openModal(
				nextButtonName?: string,
				nextButtonFunctionId?: string,
				prevButtonName?: string,
				prevButtonFunctionId?: string,
				withClose?: boolean,
			) {
				self.nextButtonName = nextButtonName || ""
				self.nextButtonFunctionId = nextButtonFunctionId || ""
				self.prevButtonName = prevButtonName || ""
				self.prevButtonFunctionId = prevButtonFunctionId || ""
				self.withClose = withClose || false
				self.isOpen = true
			},
			closeModal() {
				self.isOpen = false
			},
		}
	})

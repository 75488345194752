import { observer } from "mobx-react"
import { Suspense } from "preact/compat"
import { useEffect } from "preact/hooks"
import {
	Button,
	FormControl,
	HiddenVisually,
	Text,
	TextField,
	View,
} from "reshaped"

import { appStore } from "#app"
import { AceEditor } from "#components/AceEditor"

import { pipelineEmailEncryptionCreatePageStore } from "./page.model"

import { modalsStore } from "#modals"
import { ExpandableTab } from "../../components/PipelineCreation/ExpandableTab/component"
import { registerCreatePipelineFunction } from "../../utils"

export const PipelineCreateEmailEncryption = observer(() => {
	if (!appStore.auth.isAuthenticated) {
		throw Error()
	}

	const store = pipelineEmailEncryptionCreatePageStore

	useEffect(() => {
		const TransformModal = () =>
			modalsStore.transform.openModal(
				"Continue",
				"openSourceModal",
				undefined,
				undefined,
				true,
			)

		registerCreatePipelineFunction("openTransformModal", () =>
			modalsStore.onboardingTip.openModal(
				"Here, you can see all the pipeline details before creating it.<br/>We’ve already set the data source and sink for you.<br/><br/>Click <b>Deploy Pipeline</b> to continue.",
				() =>
					store.onboardingTips.setOnboardingTip(
						"deployPipeline",
						true,
					),
				true,
				true,
			),
		)

		TransformModal()
		modalsStore.onboardingTip.openModal(
			"Welcome to the Email Encryption Demo!<br/><br/>Here, you have the transform function of the pipeline. In this demo, we have already written the Python function to encrypt customers' e-mail addresses. You will be able to change the code later.<br/><br/>Click <b>Continue</b> to move to the next step!",
			() => store.onboardingTips.setOnboardingTip("welcome", true),
			false,
		)
	})

	return (
		<View paddingTop={4}>
			{/* Trigger the loading of the lazy Ace Chunk when we enter the page, not just when the transformation step is shown. */}
			<HiddenVisually>
				<Suspense fallback={null}>
					<AceEditor mode="python" />
				</Suspense>
			</HiddenVisually>
			{/* End of Trigger */}
			<View direction="column" gap={6}>
				<Text variant="title-3">Create</Text>
				<View gap={3} direction="row" align="stretch">
					<View.Item columns={4}>
						<ExpandableTab
							name="Dummy Data Generator"
							label="Source"
							onClick={() => {
								modalsStore.source.openModal(
									"Continue",
									undefined,
									undefined,
									undefined,
								)
								!store.onboardingTips.source &&
									modalsStore.onboardingTip.openModal(
										"You can connect your data source here while creating a pipeline.<br/>In this demo, we will use the Dummy Data Generator for the data source.<br/><br/>Click <b>Continue</b> to go back.",
										() =>
											store.onboardingTips.setOnboardingTip(
												"source",
												true,
											),
										true,
										true,
									)
							}}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name="E-mail Encryption"
							label="Transform"
							onClick={() =>
								modalsStore.transform.openModal(
									"Continue",
									undefined,
									undefined,
									undefined,
								)
							}
						/>
					</View.Item>
					<View.Item columns={4}>
						<ExpandableTab
							name="GlassFlow Sink"
							label="Sink"
							onClick={() => {
								modalsStore.sink.openModal(
									"Continue",
									undefined,
									undefined,
									undefined,
								)
								!store.onboardingTips.sink &&
									modalsStore.onboardingTip.openModal(
										"You can connect your data sink to the desired destination here.<br/>In this demo, it is only possible to preview data in GlassFlow.<br/><br/>Click <b>Continue</b> to go back.",
										() =>
											store.onboardingTips.setOnboardingTip(
												"sink",
												true,
											),
										true,
										true,
									)
							}}
						/>
					</View.Item>
				</View>
				<View gap={6} width="50%">
					<FormControl hasError={store.setupForm.name.hasError}>
						<FormControl.Label>Pipeline Name</FormControl.Label>
						<TextField
							name="pipelineName"
							value={store.setupForm.name.value}
							onChange={(event) => {
								store.setupForm.setName(event.value)
							}}
							inputAttributes={{
								"data-1p-ignore": true,
							}}
						/>
						<FormControl.Error>
							{store.setupForm.name.errorMessage}
						</FormControl.Error>
					</FormControl>
				</View>
				<View>
					<Button
						color="primary"
						loading={store.isCreating}
						onClick={async () => {
							store.submit()
						}}
					>
						Deploy Pipeline
					</Button>
				</View>
			</View>
		</View>
	)
})

import { RadioGroup, Text, View } from "reshaped"

import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { SinkOptions } from "../page.model"

export const DataSink = () => {
	return (
		<View minHeight={140}>
			<View paddingBottom={6} direction="row" gap={3} align="center">
				<Text variant="body-1">
					Preview your data in GlassFlow web application for testing
					purposes.
				</Text>
			</View>
			<View direction="row" align="stretch" gap={4}>
				<RadioGroup name="source">
					{SinkOptions.map((option) => (
						<CardRadio
							key={option.value}
							isSelected={option.value === SinkOptions[0].value}
							value={option.value}
							name={option.name}
							isDisabled={option.value !== SinkOptions[0].value}
						/>
					))}
				</RadioGroup>
			</View>
		</View>
	)
}

import { Auth0Client } from "@auth0/auth0-spa-js"
import { type Notification, Novu } from "@novu/js"
import * as Fathom from "fathom-client"
import { autorun } from "mobx"
import { type Instance, cast, flow, getSnapshot, types } from "mobx-state-tree"

import { ProfileModel, api, isApiError } from "#api"
import { featureFlagNotificationsCenter } from "#app"

export const TokenModel = types.model("Auth0 Tokens", {
	id_token: types.string,
	access_token: types.string,
	expires_in: types.number,
})

const NotificationsModel = types
	.model("Notifications", {
		novu: types.frozen<Novu>(),
		notifications: types.array(types.frozen<Notification>()),
		unreadCount: types.optional(types.number, 0),
		notificationToBeToasted: types.maybe(types.frozen<Notification>()),
	})
	.actions((self) => {
		let refreshNotifications = flow(function* () {
			self.notifications = cast(
				(yield self.novu.notifications.list()).data?.notifications,
			)
			let countResponse: Awaited<
				ReturnType<typeof self.novu.notifications.count>
			> = yield self.novu.notifications.count({ read: false })

			// biome-ignore lint/suspicious/noExplicitAny: The type provided from Novu is incorrect
			self.unreadCount = (countResponse as any).data.count ?? 0
		})

		return {
			showNotificationToast(notification: Notification) {
				self.notificationToBeToasted = notification
			},

			toastHasBeenToasted() {
				self.notificationToBeToasted = undefined
			},

			refreshNotifications,

			markAllAsRead: flow(function* () {
				yield self.novu.notifications.readAll()

				refreshNotifications()
			}),

			initialise: flow(function* () {
				self.notifications = cast(
					(yield self.novu.notifications.list()).data?.notifications,
				)
				let countResponse: Awaited<
					ReturnType<typeof self.novu.notifications.count>
				> = yield self.novu.notifications.count({ read: false })

				// biome-ignore lint/suspicious/noExplicitAny: The type provided from Novu is incorrect
				self.unreadCount = (countResponse as any).data.count ?? 0

				self.novu.on("notifications.notification_received", (event) => {
					if (appStore.auth.isAuthenticated) {
						appStore.auth.notifications.refreshNotifications()
						appStore.auth.notifications.showNotificationToast(
							event.result,
						)
					}
				})
			}),
		}
	})
	.views((self) => {
		return {
			get hasUnreadNotifications() {
				return self.unreadCount > 0
			},
		}
	})

export const AppModel = types
	.model("App", {
		userSettings: types.optional(
			types
				.model("UserSettings", {
					theme: types.optional(
						types.union(
							types.literal("automatic"),
							types.literal("dark"),
							types.literal("light"),
						),
						"automatic",
					),
				})
				.actions((self) => {
					const setTheme = (options: {
						theme: "dark" | "light" | "automatic"
						persistSettings?: boolean
					}) => {
						self.theme = options.theme

						if (options.persistSettings) {
							Fathom.trackEvent("Setting:Theme Set")
							Fathom.trackEvent(
								`Setting:Theme Set=${options.theme}`,
							)
							api.SaveUserExternalSettings(
								getSnapshot<Record<string, unknown>>(self),
							)
						}
					}

					return {
						setTheme,
					}
				}),
			{},
		),
		systemTheme: types.optional(
			types.union(types.literal("dark"), types.literal("light")),
			"dark",
		),
		auth: types.optional(
			types.union(
				types.model("Unauthenticated", {
					isAuthenticated: types.literal(false),
					isDetectingState: types.optional(types.boolean, true),
				}),
				types.model("Authenticated", {
					isAuthenticated: types.literal(true),
					isDetectingState: types.optional(types.boolean, false),
					tokens: TokenModel,
					profile: ProfileModel,
					notifications: NotificationsModel,
				}),
			),
			{ isAuthenticated: false, isDetectingState: true },
		),
	})
	.actions((self) => {
		const detectAuthenticationState = flow(function* (
			options: { isSignUp: boolean } = { isSignUp: false },
		) {
			self.auth.isDetectingState = true
			let tokens: Instance<typeof TokenModel> | undefined = undefined
			let profile: Instance<typeof ProfileModel> | undefined = undefined

			try {
				tokens = TokenModel.create(
					yield auth0Client.getTokenSilently({
						detailedResponse: true,
					}),
				)
			} catch (error) {
				self.auth.isDetectingState = false
			}

			if (tokens) {
				if (options.isSignUp) {
					try {
						const response = ProfileModel.create(
							yield api.signUp({
								access_token: tokens.access_token,
								id_token: tokens.id_token,
							}),
						)

						const excludedEmails = ["ashishbagri@gmail.com"]

						if (
							response.email.endsWith("glassflow.dev") ||
							excludedEmails.includes(response.email)
						) {
							Fathom.blockTrackingForMe()
						}

						self.userSettings = response.external_settings ?? {}
						profile = response
					} catch (error) {
						if (isApiError("signUp", error)) {
							// User already exists, so lets get the profile the usual way
							if (error.response.status === 409) {
								const response = ProfileModel.create(
									yield api.getProfile({
										headers: {
											Authorization: `Bearer ${tokens.access_token}`,
										},
									}),
								)

								self.userSettings =
									response.external_settings ?? {}
								profile = response
							}
						}
					}
				} else {
					try {
						const response = yield api.getProfile({
							headers: {
								Authorization: `Bearer ${tokens.access_token}`,
							},
						})

						self.userSettings = response.external_settings ?? {}
						profile = response
					} catch (error) {
						if (isApiError("getProfile", error)) {
							if (error.response.status === 401) {
								const response = ProfileModel.create(
									yield api.signUp({
										access_token: tokens.access_token,
										id_token: tokens.id_token,
									}),
								)

								self.userSettings =
									response.external_settings ?? {}
								profile = response
							}
						}
					}
				}

				// Set the Authorization header for all following requests
				api.axios.defaults.headers.common.Authorization = `Bearer ${tokens.access_token}`

				if (profile) {
					let notifications:
						| Instance<typeof NotificationsModel>
						| undefined = undefined
					if (featureFlagNotificationsCenter()) {
						notifications = NotificationsModel.create({
							novu: new Novu({
								subscriberId: profile.subscriber_id,
								applicationIdentifier: "fCOomlO5Y9A-",
								useCache: false,
								// backendUrl: "https://eu.api.novu.co",
								// socketUrl: "https://eu.ws.novu.co",
							}),
						})
						notifications.initialise()
					} else {
						notifications = NotificationsModel.create()
					}
					self.auth = {
						isAuthenticated: true,
						isDetectingState: false,
						tokens,
						profile,
						notifications,
					}
				} else {
					throw new Error("Could not find Profile!")
				}
			}
		})

		const logout = () => {
			Fathom.trackEvent("Logout")

			// Reset the global Auth Header
			api.axios.defaults.headers.common.Authorization = undefined
			auth0Client.logout({
				logoutParams: {
					returnTo: location.origin,
				},
			})
		}

		const setSystemTheme = (preference: "dark" | "light") => {
			self.systemTheme = preference
		}

		return {
			detectAuthenticationState,
			logout,
			setSystemTheme,
		}
	})
	.views((self) => {
		const resolvedTheme = (): "dark" | "light" => {
			const userTheme = self.userSettings.theme

			if (userTheme === "automatic") {
				return self.systemTheme
			} else {
				return userTheme
			}
		}

		return { resolvedTheme }
	})

export const appStore = AppModel.create({})

export const auth0Client = new Auth0Client({
	clientId: "DXnSil4YeIhICUWXbggDAHPw334FXXO5",
	domain: "https://auth.glassflow.dev",
	authorizationParams: {
		redirect_uri: location.origin,
		audience: "https://api.glassflow.dev",
	},
})

// #region Initial Theme Setup

const media = window.matchMedia("(prefers-color-scheme: dark)")
const mediaChangeHandler = (event: { matches: boolean }) => {
	if (event.matches) {
		appStore.setSystemTheme("dark")
	} else {
		appStore.setSystemTheme("light")
	}
}

media.addEventListener("change", mediaChangeHandler)

mediaChangeHandler(media) // Run once initially to set up

const localStorageThemeSetting = localStorage.getItem("themeSetting") as
	| null
	| "automatic"
	| "dark"
	| "light"

appStore.userSettings.setTheme({
	theme: localStorageThemeSetting ?? "automatic",
})

autorun(() => {
	localStorage.setItem("themeSetting", appStore.userSettings.theme)
})

// #endregion

import { observer } from "mobx-react"
import { useEffect } from "preact/hooks"
import { router } from "#router"
import { pipelinesPageStore } from "../Pipelines/page.model"

export const Root = observer(() => {
	useEffect(() => {
		setTimeout(
			() =>
				pipelinesPageStore.hasPipelines()
					? router.push("PipelinesList")
					: router.push("Onboarding"),
			100,
		)
	})

	return null
})

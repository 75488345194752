import { observer } from "mobx-react"
import { Edit, GitHub, X } from "react-feather"
import { Button, Card, Icon, Modal, Text, View } from "reshaped"
import css from "./expandableTab.module.css"

type ExpandableTabTopPanelProps = {
	title: string
	onPrevClick: () => void
	onNextClick: () => void
	onClose: () => void
	validate: () => boolean
	prevButtonName: string
	nextButtonName: string
	withClose?: boolean
}

export const ExpandableTabTopPanel: React.FunctionComponent<ExpandableTabTopPanelProps> =
	observer((props) => {
		return (
			<View
				direction="row"
				justify="space-between"
				align="center"
				paddingEnd={0}
				gap={4}
			>
				<Modal.Title>{props.title}</Modal.Title>
				<View gap={4} align="center" direction="row">
					{props.prevButtonName && (
						<Button
							color="neutral"
							onClick={async () => {
								if (await props.validate()) {
									props.onClose()
									props.onPrevClick &&
										setTimeout(
											() => props.onPrevClick(),
											200,
										)
								}
							}}
						>
							{props.prevButtonName}
						</Button>
					)}
					{props.nextButtonName && (
						<Button
							color="primary"
							onClick={async () => {
								if (await props.validate()) {
									props.onClose()
									props.onNextClick &&
										setTimeout(
											() => props.onNextClick(),
											200,
										)
								}
							}}
						>
							{props.nextButtonName}
						</Button>
					)}
					{props.withClose && (
						<Button
							color="neutral"
							variant="ghost"
							onClick={async () => {
								if (await props.validate()) {
									props.onClose()
								}
							}}
						>
							<Icon size={4} svg={<X />} />
						</Button>
					)}
				</View>
			</View>
		)
	})

type ExpandableTabProps = {
	label?: string
	name: string
	onClick?: () => void
	isEditable?: boolean
	isError?: boolean
}
export const ExpandableTab: React.FunctionComponent<ExpandableTabProps> = (
	props,
) => {
	return (
		<View direction="column" gap={2}>
			{props.label && (
				<Text variant="body-3" weight="medium">
					{props.label}
				</Text>
			)}
			<Card
				attributes={{
					style: {
						borderColor: props.isError
							? "var(--rs-color-foreground-critical)"
							: "",
					},
				}}
				className={css.card}
				as="label"
				padding={0}
				onClick={props.onClick}
			>
				<View
					gap={4}
					wrap={false}
					align="center"
					direction="row"
					justify="space-between"
					height="100%"
					padding={4}
				>
					<Text variant="body-2" weight="medium">
						{props.name}
					</Text>
					{props.isEditable && <Edit />}
				</View>
			</Card>
		</View>
	)
}

import { RadioGroup, Text, View } from "reshaped"

import { CardRadio } from "../../../components/PipelineCreation/CardRadio/component"
import { SourceOptions } from "../page.model"

export const DataSource = () => {
	return (
		<View minHeight={140}>
			<View paddingBottom={6} direction="row" gap={3} align="center">
				<Text variant="body-1">
					We generate dummy data so you can test pipelines quickly.
				</Text>
			</View>
			<View direction="row" align="stretch" gap={4}>
				<RadioGroup name="source">
					{SourceOptions.map((option) => (
						<CardRadio
							key={option.value}
							isSelected={option.value === SourceOptions[0].value}
							value={option.value}
							name={option.name}
							isDisabled={option.value !== SourceOptions[0].value}
						/>
					))}
				</RadioGroup>
			</View>
		</View>
	)
}

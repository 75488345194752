export enum PipelineCreateType {
	EmailEncryption = "EmailEncryption",
	Blank = "Blank",
}

export enum NavigationSectionsType {
	Create = "Create",
	Pipelines = "Pipelines",
	Spaces = "Spaces",
}

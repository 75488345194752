import { observer } from "mobx-react"
import { Card, Link, Skeleton, Text, View } from "reshaped"
import { appStore } from "#app"
import { getLinkProps, router } from "#router"
import File from "../../../public/icons/file.svg?react"
import Github from "../../../public/icons/github.svg?react"
import css from "./page.module.css"

export const Home = observer(() => {
	return (
		<View paddingTop={4}>
			<View paddingBottom={10}>
				{appStore.auth.isAuthenticated ? (
					<Text variant="title-1" className={css.welcome}>
						Welcome {appStore.auth.profile.name}!
					</Text>
				) : (
					<Skeleton width="100%" height={9} />
				)}
				<Text variant="body-1">
					Create a pipeline using the visual editor or use the jupyter
					quick start guide
				</Text>
			</View>
			{/* <View paddingBottom={14}>
				<Text variant="title-4">Demo projects</Text>
				<View insetTop={4} gap={3} direction="row" align="stretch">
					<View.Item columns={4}>
						<Link
							variant="plain"
							href={router.PipelinesCreateEmailEncryption()}
							onClick={
								getLinkProps(
									router.PipelinesCreateEmailEncryption(),
								).onClick
							}
						>
							<Card as="label" padding={8} height="100%">
								<View
									gap={4}
									wrap={false}
									align="center"
									direction="row"
									height="100%"
								>
									<Icon
										size={9}
										color="primary"
										svg={<Lock />}
									/>
									<Text variant="body-2" weight="medium">
										E-mail Encryption
									</Text>
								</View>
							</Card>
						</Link>
					</View.Item>
					<View.Item columns={4}>
					  <Card as="label" padding={8} height="100%">
					    <View
					      gap={4}
					      wrap={false}
					      align="center"
					      direction="row"
					      height="100%"
					    >
					      <Icon size={9} color="primary" svg={<Shuffle />} />
					      <Text variant="body-2" weight="medium">
					        Data Format
					        <br />
					        Conversion
					      </Text>
					    </View>
					  </Card>
					</View.Item>
				</View>
			</View> */}
			<View insetTop={4} gap={3} direction="row" align="stretch">
				<View.Item columns={6}>
					<Link
						variant="plain"
						attributes={{ target: "_blank" }}
						href="https://github.com/glassflow/glassflow-examples/blob/main/getting-started.ipynb"
					>
						<Card as="label" padding={0} className={css.card}>
							<View
								gap={4}
								wrap={false}
								height="100%"
								align="center"
								direction="row"
								padding={8}
							>
								<Github height="36px" />
								<Text variant="body-2" weight="medium">
									Quick Start with Jupyter
								</Text>
							</View>
						</Card>
					</Link>
				</View.Item>
				<View.Item columns={6}>
					<Link
						variant="plain"
						href={router.PipelinesCreateBlank()}
						onClick={
							getLinkProps(router.PipelinesCreateBlank()).onClick
						}
					>
						<Card as="label" padding={0} className={css.card}>
							<View
								gap={4}
								wrap={false}
								height="100%"
								align="center"
								direction="row"
								padding={8}
							>
								<File height="36px" />
								<Text variant="body-2" weight="medium">
									Create Pipeline in Visual Editor
								</Text>
							</View>
						</Card>
					</Link>
				</View.Item>
			</View>
		</View>
	)
})

import { observer } from "mobx-react"
import { Copy, Edit3, MoreVertical, Plus, Trash2, X } from "react-feather"
import {
	Button,
	Card,
	DropdownMenu,
	Table,
	Text,
	View,
	useToast,
} from "reshaped"

import { modalsStore } from "#modals"

import { pipelineDetailPageStore } from "../page.model"
import css from "./AccessTokens.module.css"

export const AccessTokens = observer(() => {
	let accessTokensStore = pipelineDetailPageStore.accessTokens
	const toast = useToast()

	let content = null

	if (accessTokensStore.tokens) {
		if (accessTokensStore.tokens.length > 0) {
			content = (
				<Card padding={0}>
					<Table>
						<Table.Row highlighted>
							<Table.Heading verticalAlign="center">
								Name
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								Created On
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								<View paddingInline={2}>Token</View>
							</Table.Heading>
							<Table.Heading />
						</Table.Row>
						{accessTokensStore.tokens.map((token) => {
							return (
								<Table.Row key={token.id}>
									<Table.Cell verticalAlign="center">
										{token.name}
									</Table.Cell>
									<Table.Cell verticalAlign="center">
										{Intl.DateTimeFormat("en", {
											dateStyle: "long",
											timeStyle: "medium",
										}).format(Date.parse(token.created_at))}
									</Table.Cell>
									<Table.Cell verticalAlign="center">
										<Button
											variant="ghost"
											endIcon={Copy}
											className={css.copyToken}
											onClick={async () => {
												await navigator.clipboard.writeText(
													token.token,
												)

												const id = toast.show({
													color: "neutral",
													icon: Copy,
													title: "Copied Token",
													actionsSlot: (
														<Button
															variant="ghost"
															icon={X}
															onClick={() =>
																toast.hide(id)
															}
														/>
													),

													timeout: "short",
													position: "bottom-end",
												})
											}}
										>{`${token.token.slice(
											0,
											3,
										)}…${token.token.slice(-3)}`}</Button>
									</Table.Cell>
									<Table.Cell
										align="end"
										verticalAlign="center"
									>
										<DropdownMenu position="bottom-end">
											<DropdownMenu.Trigger>
												{(attributes) => (
													<Button
														variant="ghost"
														color="primary"
														attributes={{
															...attributes,
															ariaLabel:
																"Token Actions",
														}}
														icon={MoreVertical}
													/>
												)}
											</DropdownMenu.Trigger>
											<DropdownMenu.Content>
												<DropdownMenu.Section>
													<DropdownMenu.Item
														icon={Edit3}
														onClick={() => {
															let pipeline =
																accessTokensStore.pipeline
															if (pipeline) {
																modalsStore.renameAccessToken.openModal(
																	token,
																	pipeline.id,
																	() => {
																		return accessTokensStore.getTokens()
																	},
																)
															}
														}}
													>
														Rename
													</DropdownMenu.Item>
												</DropdownMenu.Section>
												<DropdownMenu.Section>
													<DropdownMenu.Item
														color="critical"
														icon={Trash2}
														onClick={() => {
															let pipeline =
																accessTokensStore.pipeline

															if (pipeline) {
																modalsStore.deleteAccessToken.openModal(
																	token,
																	pipeline.id,
																	() => {
																		return accessTokensStore.getTokens()
																	},
																)
															}
														}}
													>
														Delete
													</DropdownMenu.Item>
												</DropdownMenu.Section>
											</DropdownMenu.Content>
										</DropdownMenu>
									</Table.Cell>
								</Table.Row>
							)
						})}
					</Table>
				</Card>
			)
		} else {
			content = (
				<View padding={4}>
					<Text align="center" variant="body-2">
						You currently have no Access Tokens.
					</Text>
				</View>
			)
		}
	}

	return (
		<View gap={4}>
			<View direction="row" justify="space-between" align="center">
				<Text variant="body-3">
					List of Access Tokens for this Pipeline.
				</Text>
				<Button
					icon={Plus}
					variant="faded"
					color="positive"
					onClick={() => {
						let pipeline = pipelineDetailPageStore.pipeline

						if (pipeline) {
							modalsStore.createAccessToken.openModal(
								pipeline.id,
								() => {
									return accessTokensStore.getTokens()
								},
							)
						}
					}}
				>
					Create New Token
				</Button>
			</View>

			{content}
		</View>
	)
})

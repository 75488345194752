import { AnimatePresence } from "framer-motion"
import { observer } from "mobx-react"
import type { Instance } from "mobx-state-tree"
import type { JSX } from "preact/jsx-runtime"
import { Edit2, MoreVertical, Pause, Play, Plus, Trash2 } from "react-feather"
import {
	Badge,
	Button,
	Card,
	DropdownMenu,
	Link,
	Loader,
	Pagination,
	Table,
	Text,
	View,
} from "reshaped"

import type { PipelineModel } from "#api"
import { AnimatedVerticalPresence } from "#components/AnimatedVerticalPresence"
import { modalsStore } from "#modals"
import { getLinkProps, router } from "#router"

import { pipelinesPageStore } from "./page.model"

const NoPipelines = observer(() => {
	return (
		<View align="center" justify="center" gap={10} height="70dvh">
			<View gap={2}>
				<Text variant="title-1" align="center">
					No Pipelines
				</Text>

				<Text align="center" variant="body-2" color="neutral-faded">
					Create a new Pipeline to get started or <br />
					follow the{" "}
					<Link
						href="https://docs.glassflow.dev/get-started/quickstart"
						attributes={{ target: "_blank" }}
						variant="plain"
					>
						Quick Start Guide
					</Link>
					.
				</Text>
			</View>
			<Button
				color="positive"
				href={router.PipelinesCreateBlank()}
				onClick={getLinkProps(router.PipelinesCreateBlank()).onClick}
			>
				Create New Pipeline
			</Button>
		</View>
	)
})

const Pipeline = observer(
	({ pipeline }: { pipeline: Instance<typeof PipelineModel> }) => {
		return (
			<Table.Row>
				<Table.Cell verticalAlign="center">
					<Link
						variant="plain"
						color="inherit"
						href={router.PipelinesDetailDetails({
							pipelineId: pipeline.id,
						})}
						onClick={
							getLinkProps(
								router.PipelinesDetailDetails({
									pipelineId: pipeline.id,
								}),
							).onClick
						}
					>
						{pipeline.name}
					</Link>
				</Table.Cell>
				<Table.Cell verticalAlign="center">
					{pipeline.space_name}
				</Table.Cell>
				<Table.Cell verticalAlign="center">
					{pipeline.isRunning ? (
						<Badge color="positive" variant="faded">
							Running
						</Badge>
					) : (
						<Badge color="warning" variant="faded">
							Paused
						</Badge>
					)}
				</Table.Cell>
				<Table.Cell align="end" verticalAlign="center">
					<DropdownMenu position="bottom-end">
						<DropdownMenu.Trigger>
							{(attributes) => (
								<Button
									variant="ghost"
									color="neutral"
									attributes={{
										...attributes,
										ariaLabel: "Pipeline Actions",
									}}
									icon={MoreVertical}
								/>
							)}
						</DropdownMenu.Trigger>
						<DropdownMenu.Content>
							<DropdownMenu.Section>
								<DropdownMenu.Item
									color="neutral"
									href={router.PipelinesDetailDetails({
										pipelineId: pipeline.id,
									})}
									onClick={
										getLinkProps(
											router.PipelinesDetailDetails({
												pipelineId: pipeline.id,
											}),
										).onClick
									}
								>
									View Details
								</DropdownMenu.Item>
							</DropdownMenu.Section>
							<DropdownMenu.Section>
								{pipeline.isRunning ? (
									<DropdownMenu.Item
										icon={Pause}
										color="neutral"
										onClick={() => {
											pipeline.pause()
										}}
									>
										Pause
									</DropdownMenu.Item>
								) : (
									<DropdownMenu.Item
										icon={Play}
										color="neutral"
										onClick={() => {
											pipeline.resume()
										}}
									>
										Resume
									</DropdownMenu.Item>
								)}
								<DropdownMenu.Item
									icon={Edit2}
									color="neutral"
									onClick={() => {
										modalsStore.renamePipeline.openModal(
											pipeline.id,
											pipeline.name,
											() => {
												return pipelinesPageStore.getPipelinesPage(
													{
														page: pipelinesPageStore.currentPage,
														hideFetching: true,
													},
												)
											},
										)
									}}
								>
									Rename
								</DropdownMenu.Item>
							</DropdownMenu.Section>
							<DropdownMenu.Section>
								<DropdownMenu.Item
									color="critical"
									icon={Trash2}
									onClick={() => {
										modalsStore.deletePipeline.openModal(
											pipeline.id,
											pipeline.name,
											() => {
												return pipelinesPageStore.getPipelinesPage(
													{
														page: pipelinesPageStore.currentPage,
														hideFetching: true,
													},
												)
											},
										)
									}}
								>
									Delete
								</DropdownMenu.Item>
							</DropdownMenu.Section>
						</DropdownMenu.Content>
					</DropdownMenu>
				</Table.Cell>
			</Table.Row>
		)
	},
)

export const Pipelines = observer(() => {
	let content: JSX.Element

	if (pipelinesPageStore.isFetchingPipelines) {
		content = (
			<AnimatedVerticalPresence key="Loader">
				<View gap={3} paddingTop={4}>
					<View paddingBottom={1} height="40px" />

					<View
						align="center"
						justify="center"
						height="25dvh"
						gap={3}
					>
						<Loader size="medium" />
						<Text color="neutral-faded">
							Loading your Pipelines...
						</Text>
					</View>
				</View>
			</AnimatedVerticalPresence>
		)
	} else {
		if (pipelinesPageStore.hasPipelines()) {
			let pagination: React.JSX.Element | null = null

			if (pipelinesPageStore.needsPagination()) {
				pagination = (
					<Pagination
						total={pipelinesPageStore.amountOfPages()}
						page={pipelinesPageStore.currentPage}
						previousAriaLabel="Previous page"
						nextAriaLabel="Next page"
						pageAriaLabel={(args) => `Page ${args.page}`}
						onChange={(args) => {
							pipelinesPageStore.getPipelinesPage({
								page: args.page,
								hideFetching: true,
							})
						}}
					/>
				)
			}

			content = (
				<AnimatedVerticalPresence key="Pipelines">
					<View gap={3} paddingTop={4}>
						<View
							paddingBottom={1}
							direction="row"
							justify="space-between"
						>
							<Text variant="title-3">Pipelines</Text>

							<Button
								icon={<Plus size="16px" />}
								color="primary"
								href={router.Onboarding()}
								onClick={
									getLinkProps(router.Onboarding()).onClick
								}
							>
								Create New Pipeline
							</Button>
						</View>

						<Card elevated padding={0}>
							<Table>
								<Table.Row highlighted>
									<Table.Heading verticalAlign="center">
										Name
									</Table.Heading>
									<Table.Heading verticalAlign="center">
										Space
									</Table.Heading>
									<Table.Heading verticalAlign="center">
										State
									</Table.Heading>
									<Table.Heading verticalAlign="center">
										<View align="end">{pagination}</View>
									</Table.Heading>
								</Table.Row>
								{pipelinesPageStore.pipelines.map(
									(pipeline) => {
										return (
											<Pipeline
												pipeline={pipeline}
												key={pipeline.id}
											/>
										)
									},
								)}
							</Table>
						</Card>
					</View>
				</AnimatedVerticalPresence>
			)
		} else {
			content = (
				<AnimatedVerticalPresence key="NoPipelines">
					<NoPipelines />
				</AnimatedVerticalPresence>
			)
		}
	}

	return (
		<AnimatePresence initial={false} mode="wait">
			{content}
		</AnimatePresence>
	)
})

import { observer } from "mobx-react"
import { Modal, View } from "reshaped"

import { useLocation } from "@swan-io/chicane"
import { useEffect } from "preact/hooks"
import { modalsStore } from "#modals"
import { ExpandableTabTopPanel } from "../../components/PipelineCreation/ExpandableTab/component"
import { PipelineCreateType } from "../../enums"
import { pipelineCreatePageStore } from "../../pages/PipelineCreate/page.model"
import { DataSink } from "../../pages/PipelineCreate/tabs/dataSink"
import { DataSink as DataSinkEmailEncryption } from "../../pages/PipelineCreateEmailEncryption/tabs/dataSink"
import { getCreatePipelineFunction, getPipelineCreateType } from "../../utils"
import css from "./modal.module.css"

export const getView = () => {
	switch (getPipelineCreateType()) {
		case PipelineCreateType.EmailEncryption:
			return <DataSinkEmailEncryption />
		case PipelineCreateType.Blank:
			return <DataSink />
		default:
			return <DataSink />
	}
}

export const SinkModal = observer(() => {
	const sinkModalStore = modalsStore.sink
	const store = pipelineCreatePageStore
	const location = useLocation()

	useEffect(() => {
		if (location.raw.path !== "/pipelines/create") {
			sinkModalStore.closeModal()
		}
	}, [location.raw.path, sinkModalStore.closeModal])

	return (
		<Modal
			className="modal"
			active={sinkModalStore.isOpen}
			padding={6}
			size="1024px"
			overlayClassName={css.overlay0}
			blurredOverlay
		>
			<ExpandableTabTopPanel
				title="Data Sink"
				onClose={sinkModalStore.closeModal}
				onNextClick={() =>
					getCreatePipelineFunction(
						sinkModalStore.nextButtonFunctionId,
					)()
				}
				onPrevClick={() =>
					getCreatePipelineFunction(
						sinkModalStore.prevButtonFunctionId,
					)()
				}
				validate={store.isSinkFormValid}
				prevButtonName={sinkModalStore.prevButtonName}
				nextButtonName={sinkModalStore.nextButtonName}
				withClose={sinkModalStore.withClose}
			/>
			<View paddingTop={4}>{getView()}</View>
		</Modal>
	)
})

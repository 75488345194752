import { observer } from "mobx-react"
import type { Instance } from "mobx-state-tree"
import { Copy, Edit2, Info, MoreVertical, Plus, Trash2, X } from "react-feather"
import {
	Badge,
	type BadgeProps,
	Button,
	Card,
	DropdownMenu,
	Link,
	Pagination,
	Skeleton,
	Table,
	Text,
	View,
	useToast,
} from "reshaped"

import type { SpaceModel } from "#api"
import { modalsStore } from "#modals"

import { spacesPageStore } from "./page.model"
import css from "./page.module.css"

const Space = observer(({ space }: { space: Instance<typeof SpaceModel> }) => {
	const toast = useToast()

	let permissionColor: BadgeProps["color"]
	let permissionLabel: string

	if (space.permission === "admin") {
		permissionColor = "positive"
		permissionLabel = "Administrator"
	} else if (space.permission === "writer") {
		permissionColor = "primary"
		permissionLabel = "Writer"
	} else {
		permissionColor = undefined
		permissionLabel = "Reader"
	}

	return (
		<Table.Row>
			<Table.Cell verticalAlign="center">{space.name}</Table.Cell>
			<Table.Cell verticalAlign="center">
				<Badge variant="faded" rounded color={permissionColor}>
					{permissionLabel}
				</Badge>
			</Table.Cell>
			<Table.Cell verticalAlign="center">
				<Button
					variant="ghost"
					endIcon={<Copy size={14} />}
					className={css.copyToken}
					onClick={async () => {
						await navigator.clipboard.writeText(space.id)

						const id = toast.show({
							color: "neutral",
							icon: Copy,
							title: "Copied Space ID",
							actionsSlot: (
								<Button
									variant="ghost"
									icon={X}
									onClick={() => toast.hide(id)}
								/>
							),

							timeout: "short",
							position: "bottom-end",
						})
					}}
				>{`${space.id.slice(0, 4)}…${space.id.slice(-4)}`}</Button>
			</Table.Cell>
			<Table.Cell align="end" verticalAlign="center">
				<DropdownMenu position="bottom-end">
					<DropdownMenu.Trigger>
						{(attributes) => (
							<Button
								variant="ghost"
								color="neutral"
								attributes={{
									...attributes,
									ariaLabel: "Space Actions",
								}}
								icon={MoreVertical}
							/>
						)}
					</DropdownMenu.Trigger>
					<DropdownMenu.Content>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								icon={Edit2}
								onClick={() => {
									modalsStore.renameSpace.openModal(
										space.id,
										space.name,
										() => {
											return spacesPageStore.getSpacesPage(
												{
													page: spacesPageStore.currentPage,
													hideFetching: true,
												},
											)
										},
									)
								}}
							>
								Rename
							</DropdownMenu.Item>
						</DropdownMenu.Section>
						<DropdownMenu.Section>
							<DropdownMenu.Item
								color="critical"
								icon={Trash2}
								onClick={() => {
									modalsStore.deleteSpace.openModal(
										space.id,
										space.name,
										() => {
											return spacesPageStore.getSpacesPage(
												{
													page: spacesPageStore.currentPage,
													hideFetching: true,
												},
											)
										},
									)
								}}
							>
								Delete
							</DropdownMenu.Item>
						</DropdownMenu.Section>
					</DropdownMenu.Content>
				</DropdownMenu>
			</Table.Cell>
		</Table.Row>
	)
})

export const Spaces = observer(() => {
	let content: React.JSX.Element

	if (spacesPageStore.isFetchingSpaces) {
		content = <Skeleton height={40} borderRadius="small" />
	} else {
		if (spacesPageStore.hasSpaces()) {
			let pagination: React.JSX.Element | null = null

			if (spacesPageStore.needsPagination()) {
				pagination = (
					<Pagination
						total={spacesPageStore.amountOfPages()}
						page={spacesPageStore.currentPage}
						previousAriaLabel="Previous page"
						nextAriaLabel="Next page"
						pageAriaLabel={(args) => `Page ${args.page}`}
						onChange={(args) => {
							spacesPageStore.getSpacesPage({
								page: args.page,
								hideFetching: true,
							})
						}}
					/>
				)
			}

			content = (
				<Card elevated padding={0}>
					<Table>
						<Table.Row highlighted>
							<Table.Heading verticalAlign="center">
								Name
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								Access
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								<View paddingInline={2}>ID</View>
							</Table.Heading>
							<Table.Heading verticalAlign="center">
								<View align="end">{pagination}</View>
							</Table.Heading>
						</Table.Row>
						{spacesPageStore.spaces.map((space) => {
							return <Space space={space} key={space.id} />
						})}
					</Table>
				</Card>
			)
		} else {
			content = <div>You seem to have no Spaces.</div>
		}
	}

	return (
		<View gap={3} paddingTop={4}>
			<View paddingBottom={1} direction="row" justify="space-between">
				<View>
					<Text variant="title-3">Spaces</Text>

					<Text variant="body-3">
						Spaces allow you to organise your pipelines.{" "}
						<Link
							href="https://docs.glassflow.dev/concepts/space"
							attributes={{ target: "_blank" }}
							variant="plain"
						>
							Learn more
						</Link>
					</Text>
				</View>
				<Button
					icon={<Plus size="16px" />}
					color="primary"
					disabled={modalsStore.createSpace.isCreating}
					onClick={() => {
						modalsStore.createSpace.openModal(() => {
							return spacesPageStore.getSpacesPage({
								page: spacesPageStore.currentPage,
								hideFetching: true,
							})
						})
					}}
				>
					Create New Space
				</Button>
			</View>

			{content}
		</View>
	)
})

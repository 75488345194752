import { observer } from "mobx-react"
import { Dismissible, Modal } from "reshaped"
import css from "./modal.module.css"

import classNames from "classnames"
import { modalsStore } from "#modals"

export const OnboardingTipModal = observer(() => {
	const modalStore = modalsStore.onboardingTip

	return (
		<Modal
			className={classNames(css.modal, {
				[css.bottom]: modalStore.isBottomAligned,
			})}
			active={modalStore.isOpen}
			padding={6}
			overlayClassName={
				modalStore.isTransparentOverlay ? css.overlay0 : css.overlay25
			}
			size="540px"
			onClose={() => {
				modalStore.closeModal()
				modalStore.onCloseClick()
			}}
		>
			<Dismissible
				onClose={() => {
					modalStore.closeModal()
					modalStore.onCloseClick()
				}}
				closeAriaLabel="Close modal"
			>
				{/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
				<div dangerouslySetInnerHTML={{ __html: modalStore.text }} />
			</Dismissible>
		</Modal>
	)
})
